import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css, ThemeContext } from 'styled-components';
import { Query } from 'react-apollo';
import MediaQuery from 'react-responsive';
import { Transition } from 'react-transition-group';
import currentUserForHeaderQuery from 'graphql/queries/currentUserForHeader.graphql';
import Phone from 'components/atoms/Phone';
import Link from 'components/atoms/Link';
import HamburgerButton from 'components/atoms/HamburgerButton';
import Overlay from 'components/atoms/Overlay';
import LockBodyScroll from 'components/atoms/LockBodyScroll';

const ANIMATION_DURATION = 300;

const MobileWrapper = styled.div`
  margin: -0.5em;
  z-index: 2;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    display: none;
  }
`;

const cssByAnimationState = {
  entering: css`
    transition: transform ${ANIMATION_DURATION}ms;
    transform: none;
  `,
  entered: css`
    transform: none;
  `,
  exiting: css`
    transition: transform ${ANIMATION_DURATION}ms;
    transform: translateX(100%);
  `,
  exited: css`
    transform: translateX(100%);
  `,
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 75%;
  max-width: 20em;
  transform: translateX(100%);
  background: ${({ theme }) => theme.colors.secondary};
  z-index: 2;

  display: flex;
  flex-direction: column;
  padding: 1em;
  overflow: hidden auto;

  ${({ animationState }) => cssByAnimationState[animationState]};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    position: static;
    width: calc(100% + 2em);
    max-width: none;
    transform: none;
    background: none;
    flex-direction: row;
    padding: 0;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -1em;
  }
`;

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  margin-top: 0.5em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    flex-direction: row;
    margin: 0 0.5em;
    order: 1;
  }
`;

const MainMenuLink = styled(Link)`
  text-decoration: none;
  padding: 0.5em 0.5em;
  margin: 0 -0.5em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    margin: 0;
  }
`;

const PhoneWrapper = styled.div`
  margin-bottom: 1em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    margin: 0 1em;
    order: 2;
  }
`;

const ProfileLink = styled(Link)`
  font-weight: 600;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    margin: 0 1em;
    order: 1;
  }
`;

const RightColWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    align-items: center;
    flex-direction: row;
    order: 2;
  }
`;

const MainMenu = () => {
  const location = useLocation();
  const theme = useContext(ThemeContext);

  const [isMenuShown, setMenuShown] = useState(false);

  const wrapperRef = useRef(null);

  useEffect(
    () => {
      setMenuShown(false);
    },
    [location],
  );

  useEffect(
    () => {
      if (!isMenuShown) {
        return;
      }

      function handleClick(e) {
        const el = wrapperRef.current;
        const inside = el.contains(e.target);
        if (!inside) {
          setMenuShown(false);
        }
      }

      function handleResize() {
        if (isMenuShown && window.matchMedia(`(min-width: ${theme.breakpoints[1]}em)`).matches) {
          setMenuShown(false);
        }
      }

      function onEscape({ keyCode }) {
        if (keyCode === 27) {
          setMenuShown(false);
        }
      }

      document.addEventListener('click', handleClick, true);
      document.addEventListener('keydown', onEscape);
      window.addEventListener('resize', handleResize, true);

      return () => {
        document.removeEventListener('click', handleClick, true);
        document.removeEventListener('keydown', onEscape);
        window.removeEventListener('resize', handleResize, true);
      };
    },
    [isMenuShown],
  );

  const device = {};

  if (typeof window === 'undefined') {
    device.width = 1920;
  }

  const content = (
    <Transition
      in={isMenuShown}
      appear
      onEnter={() => {
        wrapperRef.current.scrollTop;
      }}
      timeout={ANIMATION_DURATION}
    >
      {menuAnimationState => (
        <Wrapper ref={wrapperRef} animationState={menuAnimationState}>
          <RightColWrapper>
            <PhoneWrapper>
              <Phone />
            </PhoneWrapper>
            <Query query={currentUserForHeaderQuery}>
              {({ data, loading, error }) => (
                <ProfileLink to="/profile">
                  Личный кабинет
                  {!loading &&
                    !error &&
                    data.currentUser && <span>{` (${data.currentUser.firstName})`}</span>}
                </ProfileLink>
              )}
            </Query>
          </RightColWrapper>
          <StyledNav>
            <MainMenuLink to="/about">О нас</MainMenuLink>
            <MainMenuLink to="/sales">Акции</MainMenuLink>
            <MainMenuLink to="/news">Новости</MainMenuLink>
            <MainMenuLink to="/payment-and-delivery">Оплата и доставка</MainMenuLink>
            <MainMenuLink to="/feedbacks">Отзывы</MainMenuLink>
            <MainMenuLink to="/contacts">Контакты</MainMenuLink>
          </StyledNav>
        </Wrapper>
      )}
    </Transition>
  );

  return (
    <>
      <MobileWrapper>
        <HamburgerButton
          onClick={() => {
            setMenuShown(true);
          }}
          aria-label="Открыть/закрыть главное меню"
        />
      </MobileWrapper>
      <MediaQuery query={`(min-width: ${theme.breakpoints[1]}em)`} device={device}>
        {matches => (
          <>
            {(matches || !isMenuShown) && content}
            {!matches && (
              <Overlay show={isMenuShown}>
                {isMenuShown && <LockBodyScroll />}
                {content}
              </Overlay>
            )}
          </>
        )}
      </MediaQuery>
    </>
  );
};

export default MainMenu;
