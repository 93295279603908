import styled, { css } from 'styled-components';
import { darken, desaturate, lighten } from 'polished';

const primaryCss = css`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 0.5em 1em;
  font-weight: 600;
  outline: none;
  color: ${({ theme }) => theme.colors.text};

  &:hover {
    color: ${({ theme }) => theme.colors.text};
  }

  &:active,
  &:focus {
    background-color: ${({ theme }) => darken(0.1, theme.colors.primary)};
  }

  &:disabled {
    background-color: ${({ theme }) => lighten(0.2, desaturate(0.5, theme.colors.primary))};
  }
`;

const accent2Css = css`
  background-color: ${({ theme }) => theme.colors.accent2};
  padding: 0.5em 1em;
  font-weight: 600;
  outline: none;
  color: ${({ theme }) => theme.colors.text};

  &:hover {
    color: ${({ theme }) => theme.colors.text};
  }

  &:active,
  &:focus {
    background-color: ${({ theme }) => darken(0.1, theme.colors.accent2)};
  }

  &:disabled {
    background-color: ${({ theme }) => lighten(0.2, desaturate(0.5, theme.colors.accent2))};
  }
`;

const secondaryCss = css`
  background-color: ${({ theme }) => theme.colors.secondary};
  font-weight: 600;
  outline: none;
  color: ${({ theme }) => theme.colors.text};

  &:active,
  &:focus {
    background-color: ${({ theme }) => darken(0.1, theme.colors.secondary)};
  }
`;

const neutralCss = css`
  background-color: ${({ theme }) => theme.colors.neutral4};
  font-weight: 600;
  outline: none;
  color: ${({ theme }) => theme.colors.text};

  &:active,
  &:focus {
    background-color: ${({ theme }) => darken(0.1, theme.colors.neutral4)};
  }
`;

const inlineCss = css`
  display: inline-block;
`;

const Button = styled.button`
  border: none;
  background: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-align: center;
  user-select: none;
  padding: 0.5em;
  cursor: pointer;
  display: block;
  border-radius: 0.25em;
  color: ${({ theme }) => theme.colors.text};

  &:active,
  &:focus {
    background-color: ${({ theme }) => theme.colors.neutral4};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.colors.neutral3};
      cursor: default;

      &:hover {
        color: ${({ theme }) => theme.colors.neutral3};
      }

      &:active,
      &:focus {
        background: none;
      }
    `};

  ${({ primary }) => primary && primaryCss};
  ${({ accent2 }) => accent2 && accent2Css};
  ${({ secondary }) => secondary && secondaryCss};
  ${({ neutral }) => neutral && neutralCss};
  ${({ inline }) => inline && inlineCss};
  ${({ big }) =>
    big &&
    css`
      padding: 1em;
    `};
`;

export default Button;
