export default {
  breakpoints: [36, 48, 62, 75],
  containerMaxWidths: [1, 1, 1, 75],
  colors: {
    accent1: '#7ed321',
    accent2: '#f8e71c',
    primary: '#f3c329',
    secondary: '#ffffff',
    neutral1: '#000000',
    neutral2: '#4a4a4a',
    neutral3: '#9b9b9b',
    neutral4: '#e7e7e7',
    neutral5: '#f4f4f4',
    text: '#212529',
    errorText: '#a8280f',
    error: '#f5c6cb',
    successText: '#2b5a2c',
    success: '#a8dcb3',
    infoText: '#367abf',
    info: '#8fd0e1',
    danger: '#dc3545',
  },
};
