import PropTypes from 'prop-types';
import Menu, { MenuLink } from 'components/atoms/Menu';

const Children = ({ children }) => children;

const CategoriesList = ({ className, categories, withoutWrapper }) => {
  const Wrapper = withoutWrapper ? Children : Menu;

  return (
    <Wrapper className={className}>
      {categories.map((category) => (
        <MenuLink key={category.id} to={`/catalog/${category.translit}`}>
          {category.name}
        </MenuLink>
      ))}
    </Wrapper>
  );
};

CategoriesList.propTypes = {
  withoutWrapper: PropTypes.bool,
  className: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      translit: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default CategoriesList;
