import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import FullSearch from './FullSearch';
import MobileSearch from './MobileSearch';

@withRouter
export default class Search extends Component {
  static propTypes = {
    mobile: PropTypes.bool,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    this.state = {
      q: queryParams.q || '',
      type: queryParams.type || 'name',
    };
  }

  onChangeQ = (event) => {
    this.setState({
      q: event.target.value,
    });
  };

  onChangeType = (event) => {
    this.setState({
      type: event.target.value,
    });
  };

  search = (e) => {
    e.preventDefault();

    if (this.state.q.length > 0) {
      this.props.history.push(`/search?q=${this.state.q}&type=${this.state.type}`);
    }
  };

  render() {
    const { mobile, ...rest } = this.props;

    const Comp = mobile ? MobileSearch : FullSearch;

    return (
      <Comp
        {...rest}
        {...this.state}
        onChangeQ={this.onChangeQ}
        onChangeType={this.onChangeType}
        search={this.search}
      />
    );
  }
}
