import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import CaretIcon from 'react-icons/lib/fa/caret-down';
import Button from 'components/atoms/Button';
import Bubble from './Bubble';
import Context from './Context';

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const Title = styled(Button)`
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;
  padding: 1.2em 0.5em;
  margin: 0 -0.5em;
  display: flex;
  align-items: center;
`;

const StyledCaretIcon = styled(({ rotate, ...rest }) => <CaretIcon {...rest} />)`
  display: block;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    transform: rotate(${({ rotate }) => (rotate ? -180 : 0)}deg);
  }
`;

@withRouter
@withTheme
class CatalogMenu extends Component {
  static propTypes = {
    isMenuShownOnDesktop: PropTypes.bool,
    toggleMenuOnDesktop: PropTypes.func,
    bubbleRef: PropTypes.object,
    theme: PropTypes.shape({
      breakpoints: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
  };

  state = {
    showByDefault: !!this.props.isMenuShownOnDesktop,
    isMobile: false,
    isMenuShownOnMobile: false,
    isMenuShownOnDesktop: !!this.props.isMenuShownOnDesktop,
  };

  bubbleRef = this.props.bubbleRef || React.createRef();
  titleRef = React.createRef();

  static getDerivedStateFromProps(props) {
    if (typeof props.isMenuShownOnDesktop !== 'undefined') {
      return {
        isMenuShownOnDesktop: props.isMenuShownOnDesktop,
      };
    }

    return null;
  }

  componentDidMount() {
    this.addListeners();
    window.addEventListener('resize', this.handleResize, true);
    this.handleResize();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location !== prevProps.location &&
      (this.state.isMenuShownOnMobile ||
        (this.state.isMenuShownOnDesktop && !this.state.showByDefault))
    ) {
      this.toggleMenu();
    }
  }

  componentWillUnmount() {
    this.removeListeners();
    window.removeEventListener('resize', this.handleResize, true);
  }

  addListeners = () => {
    if (
      this.state.isMenuShownOnMobile ||
      (this.state.isMenuShownOnDesktop && !this.state.showByDefault)
    ) {
      document.addEventListener('keydown', this.handleKeydown, true);
    }

    if (this.state.isMenuShownOnMobile) {
      window.addEventListener('resize', this.handleExitMobileMode, true);
    }

    if (this.state.isMenuShownOnDesktop && !this.state.showByDefault) {
      document.addEventListener('click', this.handleClickOutside, true);
    }
  };

  removeListeners = () => {
    document.removeEventListener('keydown', this.handleKeydown, true);
    window.removeEventListener('resize', this.handleExitMobileMode, true);
    document.removeEventListener('click', this.handleClickOutside, true);
  };

  handleResize = () => {
    this.setState({
      isMobile: !window.matchMedia(`(min-width: ${this.props.theme.breakpoints[1]}em)`).matches,
    });
  };

  handleExitMobileMode = () => {
    if (!this.state.isMobile && this.state.isMenuShownOnMobile) {
      this.toggleMenu('mobile');
    }
  };

  handleClickOutside = (e) => {
    const bubble = this.bubbleRef.current;
    const title = this.titleRef.current;
    const insideBubble = bubble.contains(e.target);
    const insideTitle = title.contains(e.target);
    if (!insideBubble && !insideTitle) {
      this.toggleMenu();
    }
  };

  handleKeydown = ({ keyCode }) => {
    if (keyCode === 27) {
      this.toggleMenu();
    }
  };

  toggleMenu = (type) => {
    let t = type;

    if (!t) {
      t = !window.matchMedia(`(min-width: ${this.props.theme.breakpoints[1]}em)`).matches
        ? 'mobile'
        : 'desktop';
    }

    const varName = t === 'mobile' ? 'isMenuShownOnMobile' : 'isMenuShownOnDesktop';

    let func;

    if (t === 'desktop' && this.props.toggleMenuOnDesktop) {
      func = (callback) => this.props.toggleMenuOnDesktop(callback);
    } else {
      func = (callback) =>
        this.setState(
          (state) => ({
            [varName]: !state[varName],
          }),
          callback,
        );
    }

    func(() => {
      if (this.state[varName]) {
        this.addListeners();
      } else {
        this.removeListeners();
      }
    });
  };

  render() {
    return (
      <Wrapper>
        <Title onClick={() => this.toggleMenu()} primary ref={this.titleRef}>
          Каталог товаров <StyledCaretIcon rotate={this.state.isMenuShownOnDesktop} />
        </Title>
        <Bubble
          showOnMobile={this.state.isMenuShownOnMobile}
          showOnDesktop={this.state.isMenuShownOnDesktop}
          isMobile={this.state.isMobile}
          onCloseMobileMenu={() => this.toggleMenu('mobile')}
          innerRef={this.bubbleRef}
        />
      </Wrapper>
    );
  }
}

export default (props) => (
  <Context.Consumer>
    {({ isMenuShownOnDesktop, toggleMenuOnDesktop, ref }) => {
      if (!toggleMenuOnDesktop) {
        return <CatalogMenu {...props} />;
      }

      return (
        <CatalogMenu
          isMenuShownOnDesktop={isMenuShownOnDesktop}
          toggleMenuOnDesktop={toggleMenuOnDesktop}
          bubbleRef={ref}
        />
      );
    }}
  </Context.Consumer>
);
