import PropTypes from 'prop-types';
import styled from 'styled-components';
import InputGroup from 'components/atoms/InputGroup';
import SearchInput from './SearchInput';
import SearchSelect from './SearchSelect';
import SearchButton from './SearchButton';

const StyledSearchSelect = styled(SearchSelect)`
  border: none;
  border-radius: 0;
  padding: 0.3em 0.4em;
  flex: none;
`;

const FullSearch = ({ onChangeQ, q, type, onChangeType, search }) => (
  <form onSubmit={search}>
    <InputGroup>
      <InputGroup.Input
        component={SearchInput}
        onChange={onChangeQ}
        value={q}
        aria-label="Фраза для поиска"
      />
      <InputGroup.Divider />
      <StyledSearchSelect value={type} onChange={onChangeType} aria-label="Тип поиска" />
      <InputGroup.Divider />
      <InputGroup.Button component={SearchButton} type="submit" aria-label="Найти" />
    </InputGroup>
  </form>
);

FullSearch.propTypes = {
  onChangeQ: PropTypes.func.isRequired,
  onChangeType: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  q: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default FullSearch;
