import React, { useEffect, useRef } from 'react';

const ClickAwayListener = ({ children, onClickAway }) => {
  const nodeRef = useRef(null);

  useEffect(() => {
    function handleClick(event) {
      if (!nodeRef.current) {
        return;
      }

      if (!nodeRef.current.contains(event.target)) {
        onClickAway(event);
      }
    }

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, []);

  return React.cloneElement(children, { ref: nodeRef });
};

export default ClickAwayListener;
