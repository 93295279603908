import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 2em 0;
`;

const ThirdLine = () => (
  <Wrapper>{`Золотая Камея - все для ювелиров Ⓒ 2005 — ${new Date().getFullYear()}`}</Wrapper>
);

export default ThirdLine;
