import styled from 'styled-components';
import Container from 'components/atoms/Container';
import SubscribeButton from './SubscribeButton';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 1em 0;
`;

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const Text = styled.p`
  text-align: center;
  font-weight: 600;
  font-size: 0.86em;
  margin: 0 0 1em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    text-align: left;
    margin-bottom: 0;
    margin-right: 1em;
  }
`;

const FirstLine = () => (
  <Wrapper>
    <Container>
      <InnerWrapper>
        <Text>
          Подпишитесь на нашу новостную рассылку, что бы узнавать о новых поступлениях и акциях
          первым!
        </Text>
        <SubscribeButton />
      </InnerWrapper>
    </Container>
  </Wrapper>
);

export default FirstLine;
