import { Query } from 'react-apollo';
import { useHydrated } from '../../HydrationContext';

const isBrowser = typeof document !== 'undefined';

const QueryFromCacheAfterSSRThenNetworkOnly = (props) => {
  const isHydrated = useHydrated();

  return (
    <Query {...props} fetchPolicy={isHydrated && isBrowser ? 'network-only' : 'cache-first'} />
  );
};

export default QueryFromCacheAfterSSRThenNetworkOnly;
