import styled from 'styled-components';
import { lighten } from 'polished';

const Alert = styled.div`
  border-radius: 0.25em;
  border: 0.125em solid ${({ theme, type }) => theme.colors[type || 'neutral4']};
  background-color: ${({ theme, type }) =>
    type ? lighten(0.1, theme.colors[type]) : theme.colors.neutral5};
  color: ${({ theme, type }) => theme.colors[type ? `${type}Text` : 'text']};
  padding: ${({ narrow }) => (narrow ? 0.5 : 1)}em;
`;

export default Alert;
