import { Component } from 'react';
import Button from 'components/atoms/Button';

class SubscribeButton extends Component {
  handleClick = () => {
    window.open(
      '/subscription',
      'subscription_page',
      'width=500,height=700,menubar=no,location=no,resizable=yes,scrollbars=yes,status=yes,centerscreen=yes',
    );
  };

  render() {
    return (
      <Button secondary inline onClick={this.handleClick}>
        Подписаться на рассылку
      </Button>
    );
  }
}

export default SubscribeButton;
