import styled from 'styled-components';
import FirstLine from './FirstLine';
import SecondLine from './SecondLine';

const Header = styled.header.attrs({
  children: (
    <>
      <FirstLine />
      <SecondLine />
    </>
  ),
})``;

export default Header;
