import styled, { css } from 'styled-components';

const errorCss = css`
  border-color: ${({ theme }) => theme.colors.errorText};
`;

const disabledCss = css`
  background-color: ${({ theme }) => theme.colors.neutral5};
`;

const Input = styled.input`
  display: block;
  border: 1px solid ${({ theme }) => theme.colors.neutral4};
  border-radius: 0.25em;
  padding: 0.5em;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  min-width: 0;
  outline: 0;
  background: none;

  ${({ readOnly }) =>
    readOnly &&
    css`
      && {
        ${disabledCss};
      }
    `};

  &:disabled,
  &:read-only,
  &[readonly] {
    ${disabledCss};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  ${({ error }) => error && errorCss};
`;

export default Input;
