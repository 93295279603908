import React from 'react';
import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { Route } from 'react-router-dom';
import Link from 'components/atoms/Link';

const Context = React.createContext({
  withActive: false,
});

const Wrapper = styled.nav`
  display: block;
  border-radius: 0.25em;
  background-color: ${({ theme }) => theme.colors.neutral4};
  overflow: hidden;
`;

const activeCss = css`
  background-color: ${({ theme }) => theme.colors.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledLink = styled(({ active, ...rest }) => <Link {...rest} />)`
  text-decoration: none;
  padding: 1em;
  display: block;
  border-left: 0.2em solid ${({ theme }) => theme.colors.primary};

  &:hover {
    background-color: ${({ theme }) => lighten(0.1, theme.colors.primary)};
  }

  ${({ active }) => active && activeCss};
`;

export const MenuLink = (props) => (
  <Context.Consumer>
    {({ withActive }) =>
      withActive && props.to ? (
        <Route path={props.to} exact>
          {({ match }) => <StyledLink active={!!match} {...props} />}
        </Route>
      ) : (
        <StyledLink {...props} />
      )
    }
  </Context.Consumer>
);

const Menu = ({ withActive, className, children }) => (
  <Context.Provider value={{ withActive }}>
    <Wrapper className={className}>{children}</Wrapper>
  </Context.Provider>
);

Menu.defaultProps = {
  withActive: false,
};

export default Menu;
