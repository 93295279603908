import styled from 'styled-components';
import Container from 'components/atoms/Container';
import FirstLine from './FirstLine';
import SecondLine from './SecondLine';
import ThirdLine from './ThirdLine';

const Footer = styled.footer.attrs({
  children: (
    <>
      <FirstLine />
      <Container>
        <SecondLine />
        <ThirdLine />
      </Container>
    </>
  ),
})``;

export default Footer;
