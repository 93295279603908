import { Route } from 'react-router-dom';
import currentUserQuery from 'graphql/queries/currentUserOnlyId.graphql';
import Redirect from 'components/atoms/Redirect';
import LoadingPage from 'components/pages/LoadingPage';
import ErrorLoadingPage from 'components/pages/ErrorLoadingPage';
import QueryFromCacheAfterSSRThenNetworkOnly from 'components/atoms/QueryFromCacheAfterSSRThenNetworkOnly';

const ProtectedRoute = ({ userRequired, userQuery, component: Component, ...rest }) => (
  <Route
    {...rest}
    component={(props) => (
      <QueryFromCacheAfterSSRThenNetworkOnly query={userQuery || currentUserQuery}>
        {({ data, loading, error, fetchMore }) => {
          if (loading) {
            return <LoadingPage />;
          }

          if (error) {
            return <ErrorLoadingPage />;
          }

          const redirect = <Redirect to={userRequired ? '/signin' : '/profile'} />;

          const protectedComponent = (
            <Component currentUser={(data || {}).currentUser} fetchMore={fetchMore} {...props} />
          );

          if (!data.currentUser) {
            return userRequired ? redirect : protectedComponent;
          }

          return userRequired ? protectedComponent : redirect;
        }}
      </QueryFromCacheAfterSSRThenNetworkOnly>
    )}
  />
);

ProtectedRoute.defaultProps = {
  userRequired: true,
};

export default ProtectedRoute;
