import PropTypes from 'prop-types';
import styled from 'styled-components';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import Container from 'components/atoms/Container';

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled(Header)`
  flex: none;
`;

const StyledContainer = styled(Container)`
  flex: 1 1 auto;
  margin-top: 1em;
  margin-bottom: 1em;
  overflow: hidden;
`;

const StyledFooter = styled(Footer)`
  flex: none;
`;

const MainTemplate = ({ children, withoutFooter, containerClassName }) => (
  <Wrapper>
    <StyledHeader />
    <StyledContainer className={containerClassName}>{children}</StyledContainer>
    {!withoutFooter && <StyledFooter />}
    {process.env.NODE_ENV === 'production' && (
      <noscript>
        <div>
          <img
            src="https://mc.yandex.ru/watch/28073193"
            style={{ position: 'absolute', left: -9999 }}
            alt=""
          />
        </div>
      </noscript>
    )}
  </Wrapper>
);

MainTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  withoutFooter: PropTypes.bool,
  containerClassName: PropTypes.string,
};

export default MainTemplate;
