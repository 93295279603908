import { Query } from 'react-apollo';
import legacySaleQuery from 'graphql/queries/legacySale.graphql';
import LoadingPage from 'components/pages/LoadingPage';
import ErrorLoadingPage from 'components/pages/ErrorLoadingPage';
import NotFoundPage from 'components/pages/NotFoundPage';
import Redirect from 'components/atoms/Redirect';

const RedirectFromLegacySaleToNew = ({ match }) => (
  <Query
    query={legacySaleQuery}
    variables={{
      id: match.params.legacyId,
    }}
  >
    {({ loading, data, error }) => {
      if (loading) {
        return <LoadingPage />;
      }

      if (error) {
        return <ErrorLoadingPage />;
      }

      if (!data.sale) {
        return <NotFoundPage />;
      }

      return <Redirect to={`/sales/${data.sale.id}`} />;
    }}
  </Query>
);

export default RedirectFromLegacySaleToNew;
