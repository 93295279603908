import styled, { css } from 'styled-components';
import Container from 'components/atoms/Container';
import MainMenu from 'components/molecules/MainMenu';
import Link from 'components/atoms/Link';
import Search from 'components/molecules/Search';
import InlineFullLogo from 'components/atoms/Logo/InlineFullLogo';

const hideGtThanMobile = css`
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    width: 25%;
    padding: 0 0.625em;
  }
`;

const StyledLogoLink = styled(Link)`
  display: block;
  padding: 0.5em 0;
`;

const StyledLogo = styled(InlineFullLogo)`
  height: 1.5em;
  margin-right: 1em;
`;

const RightCol = styled.div`
  padding: 0.5em 0;
  display: flex;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    flex: 1 1 auto;
    padding: 0.5em 0.625em;
  }
`;

const StyledMobileSearch = styled(Search).attrs({
  mobile: true,
})`
  margin: -0.5em 0.5em -0.5em -0.5em;

  ${hideGtThanMobile};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5em;
  padding-bottom: 0.5em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    flex-wrap: wrap;
    justify-content: normal;
    margin: 0 -0.625em;
  }
`;

const FirstLine = () => (
  <Container>
    <Wrapper>
      <LogoWrapper>
        <StyledLogoLink to="/">
          <StyledLogo />
        </StyledLogoLink>
      </LogoWrapper>
      <RightCol>
        <StyledMobileSearch />
        <MainMenu />
      </RightCol>
    </Wrapper>
  </Container>
);

export default FirstLine;
