import PropTypes from 'prop-types';
import styled from 'styled-components';
import ButtonLink from 'components/atoms/ButtonLink';
import HeartIcon from 'components/atoms/HeartIcon';

const StyledButton = styled(ButtonLink)`
  padding: 0.5em;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.neutral2};
  display: flex;
  align-items: center;
`;

const StyledBookmarkIcon = styled(HeartIcon)`
  display: block;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1.2em;
`;

const Counter = styled.div`
  display: none;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 0.25em;
  font-size: 0.85em;
  font-weight: 600;
  padding: 0.2em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    display: block;
  }
`;

const Text = styled.span`
  display: none;
  margin: 0 0.3em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    display: block;
  }
`;

const BookmarksButton = ({ counter }) => (
  <StyledButton primary to="/profile/bookmarks" aria-label="Мои закладки">
    <StyledBookmarkIcon />
    <Text>Закладки</Text>
    {counter > 0 && <Counter>{counter}</Counter>}
  </StyledButton>
);

BookmarksButton.propTypes = {
  counter: PropTypes.number,
};

export default BookmarksButton;
