import styled from 'styled-components';
import PhoneIcon from 'react-icons/lib/fa/phone';
import Link from 'components/atoms/Link';

const StyledPhoneIcon = styled(PhoneIcon)`
  color: ${({ theme }) => theme.colors.neutral3};
  margin-right: 0.2em;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 1.4em;
  color: ${({ theme }) => theme.colors.text};
`;

export default ({ withoutIcon, className }) => (
  <StyledLink href="tel:88007001789" className={className}>
    {!withoutIcon && <StyledPhoneIcon />}
    8-800-700-1-789
  </StyledLink>
);
