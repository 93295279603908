import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import MainTemplate from 'components/templates/MainTemplate';
import Button from 'components/atoms/Button';

const StyledMainTemplate = styled(({ className, ...rest }) => (
  <MainTemplate containerClassName={className} {...rest} />
))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorLoadingPage = () => (
  <StyledMainTemplate>
    <Helmet>
      <title>Ошибка при загрузке страницы</title>
    </Helmet>
    <Wrapper>
      <p>Произошла ошибка при загрузке страницы</p>
      <Button
        primary
        onClick={() => {
          window.location.reload(true);
        }}
      >
        Повторить загрузку
      </Button>
    </Wrapper>
  </StyledMainTemplate>
);

export default ErrorLoadingPage;
