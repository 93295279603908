import PropTypes from 'prop-types';
import { Route, Redirect as RouterRedirect } from 'react-router-dom';

const Redirect = ({ from, to, status, ...rest }) => (
  <Route
    render={({ staticContext }) => {
      // there is no `staticContext` on the client, so
      // we need to guard against that here
      if (staticContext && status) {
        // eslint-disable-next-line
        staticContext.status = status;
      }
      return <RouterRedirect from={from} to={to} {...rest} />;
    }}
  />
);

Redirect.propTypes = {
  from: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  status: PropTypes.number,
  push: PropTypes.bool,
};

Redirect.defaultProps = {
  from: undefined,
  status: undefined,
  push: false,
};

export default Redirect;
