import styled from 'styled-components';
import SearchIcon from 'react-icons/lib/md/search';
import Button from 'components/atoms/Button';

const StyledSearchIcon = styled(SearchIcon)`
  font-size: 1.5em;
`;

const SearchButton = styled(Button).attrs({
  children: <StyledSearchIcon />,
})``;

export default SearchButton;
