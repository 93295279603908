import { useState } from 'react';
import styled from 'styled-components';
import Logo from 'components/atoms/Logo';
import Link from 'components/atoms/Link';
import Phone from 'components/atoms/Phone';
import Button from 'components/atoms/Button';
import SendMessageToOffice from 'components/organisms/SendMessageToOffice';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 2.5em;
  margin-left: -0.5em;
  margin-right: -0.5em;
`;

const BrandBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0.5em;
  margin-bottom: 3em;
  margin-left: -0.5em;
  margin-right: -0.5em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    width: calc(100% / 3);
    flex-direction: column;
    align-items: flex-start;
  }
`;

const BrandText = styled.div`
  width: calc(50% + 0.5em);
  padding: 0 0.5em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    width: 100%;
  }
`;

const StyledLogo = styled(Logo)`
  max-width: 15em;
  width: calc(50% + 0.5em);
  padding: 0 0.5em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    width: 100%;
    margin-bottom: 2em;
  }
`;

const FastLinksBlock = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0.5em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    width: calc(100% / 3);
  }
`;

const ContactsBlock = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0.5em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    width: calc(100% / 3);
  }
`;

const ColumnTitle = styled.div`
  font-weight: 600;
  margin-bottom: 0.5em;
`;

const StyledFastLink = styled(Link)`
  padding: 0.5em 0;
  text-decoration: none;
  word-break: break-word;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledPhone = styled(Phone)`
  font-size: 1.25em;
  font-weight: bold;
  padding: 0.25em 0;
`;

const Email = styled(Link)`
  padding: 0.5em 0;
`;

const Address = styled.div`
  padding: 0.5em 0;
`;

const SecondLine = () => {
  const [isMessageModalShown, setMessageModalState] = useState(false);

  function handleMessageModalShow() {
    setMessageModalState(true);
  }

  function handleMessageModalHide() {
    setMessageModalState(false);
  }

  return (
    <Wrapper>
      <SendMessageToOffice
        title="Заказать обратный звонок"
        initialMessage="Прошу перезвонить мне."
        subject="Заказ обратного звонка с сайта"
        isModalShown={isMessageModalShown}
        onModalHide={handleMessageModalHide}
      />
      <BrandBlock>
        <StyledLogo compact />
        <BrandText>Ювелирное оборудование и инструмент</BrandText>
      </BrandBlock>
      <FastLinksBlock>
        <ColumnTitle>Быстрые ссылки</ColumnTitle>
        <StyledFastLink to="/catalog">Каталог</StyledFastLink>
        <StyledFastLink to="/news">Новости</StyledFastLink>
        <StyledFastLink to="/sales">Акции</StyledFastLink>
        <StyledFastLink to="/privacy-policy">Политика конфиденциальности</StyledFastLink>
        <StyledFastLink to="/contacts">Контакты</StyledFastLink>
      </FastLinksBlock>
      <ContactsBlock>
        <ColumnTitle>Контакты</ColumnTitle>
        <StyledPhone withoutIcon />
        <Button neutral onClick={handleMessageModalShow}>
          Заказать обратный звонок
        </Button>
        <Email href="mailto:info@gold-kamea.ru">info@gold-kamea.ru</Email>
        <Address>
          420202, Республика Татарстан, г. Казань, ул. Чернышевского, д. 43/2, офис 424
        </Address>
      </ContactsBlock>
    </Wrapper>
  );
};

export default SecondLine;
