import { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Query } from 'react-apollo';
import CloseIcon from 'react-icons/lib/md/close';
import Button from 'components/atoms/Button';
import LockBodyScroll from 'components/atoms/LockBodyScroll';
import Menu from 'components/atoms/Menu';
import Overlay from 'components/atoms/Overlay';
import CategoriesList from 'components/molecules/CategoriesList';
import rootCategoriesQuery from 'graphql/queries/rootCategories.graphql';

export const ANIMATION_DURATION = 300;

const StyledCloseIcon = styled(CloseIcon)`
  display: block;
  font-size: 1.4em;
`;

const MobileWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: ${({ show }) => (show ? 'translateY(-100%)' : 'none')};
  display: flex;
  flex-direction: column;

  ${({ animationInProgress }) =>
    animationInProgress &&
    css`
      transition: transform ${ANIMATION_DURATION}ms;
      transform: ${({ show }) => (show ? 'none' : 'translateY(-100%)')};
    `};

  ${({ animationFinished }) =>
    animationFinished &&
    css`
      transform: ${({ show }) => (show ? 'none' : 'translateY(-100%)')};
    `};
`;

const DesktopWrapper = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  right: auto;
  bottom: auto;
  left: 0;
  width: 100%;
  transform: none;
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
  overflow: hidden;
  transition: none;
  padding-bottom: 1em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    display: block;
  }
`;

const TopBar = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.secondary};
  align-items: center;
  box-shadow: 0 0 0.5em 0 ${({ theme }) => theme.colors.neutral2};
  flex: none;
  z-index: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    display: none;
  }
`;

const ListWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral4};
  flex: 1 1 auto;
  overflow-y: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    max-height: ${({ showOnDesktop }) => (showOnDesktop ? '10000px' : 0)};
    flex: none;
    overflow: hidden;
    transform: ${({ showOnDesktop }) => (showOnDesktop ? 'none' : 'translateY(-100%)')};
    transition-property: max-height, transform;
    transition-duration: ${ANIMATION_DURATION}ms;
    background-color: transparent;
  }
`;

const StyledMenu = styled(Menu)`
  border-radius: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    border-radius: 0 0 0.25em 0.25em;
  }
`;

export default class Bubble extends Component {
  static propTypes = {
    showOnMobile: PropTypes.bool.isRequired,
    showOnDesktop: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    onCloseMobileMenu: PropTypes.func.isRequired,
    innerRef: PropTypes.object.isRequired,
  };

  state = {
    animationFinished: true,
    animationInProgress: false,
  };

  runAnimation = () => {
    if (this.finishAnimationTimeout) {
      clearTimeout(this.finishAnimationTimeout);
    }

    this.setState(
      {
        animationFinished: false,
        animationInProgress: false,
      },
      () => {
        setTimeout(() => {
          this.setState(
            {
              animationInProgress: true,
            },
            () => {
              this.finishAnimationTimeout = setTimeout(() => {
                this.setState({
                  animationInProgress: false,
                  animationFinished: true,
                });
              }, ANIMATION_DURATION);
            },
          );
        }, 0);
      },
    );
  };

  render() {
    const { showOnMobile, showOnDesktop, onCloseMobileMenu, innerRef, isMobile } = this.props;

    const content = (
      <>
        {showOnMobile && <LockBodyScroll />}
        <TopBar>
          <Button onClick={onCloseMobileMenu}>
            <StyledCloseIcon />
          </Button>
          Каталог товаров
        </TopBar>
        <ListWrapper showOnDesktop={showOnDesktop}>
          <StyledMenu>
            <Query query={rootCategoriesQuery}>
              {({ loading, data, error }) => {
                if (loading) {
                  return 'Загрузка...';
                }

                if (error) {
                  return <div>Произошла ошибка при загрузке списка категорий</div>;
                }

                return <CategoriesList categories={data.rootCategories} withoutWrapper />;
              }}
            </Query>
          </StyledMenu>
        </ListWrapper>
      </>
    );

    if (isMobile) {
      return (
        <Overlay
          show={showOnMobile}
          backdrop={false}
          onShow={this.runAnimation}
          onHide={this.runAnimation}
        >
          <MobileWrapper
            animationInProgress={this.state.animationInProgress}
            animationFinished={this.state.animationFinished}
            show={showOnMobile}
            ref={innerRef}
          >
            {content}
          </MobileWrapper>
        </Overlay>
      );
    }

    return (
      <DesktopWrapper
        show={showOnDesktop}
        animationInProgress={this.state.animationInProgress}
        ref={innerRef}
      >
        {content}
      </DesktopWrapper>
    );
  }
}
