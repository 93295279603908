import { useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { Transition } from 'react-transition-group';

const ANIMATION_DURATION = 300;

const cssByAnimationState = {
  entering: css`
    transition: opacity ${ANIMATION_DURATION}ms;
    opacity: 1;
  `,
  entered: css`
    opacity: 1;
  `,
  exiting: css`
    transition: opacity ${ANIMATION_DURATION}ms;
    opacity: 0;
  `,
  exited: css`
    opacity: 0;
  `,
};

const backdropCss = css`
  content: '';
  position: fixed;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => rgba(theme.colors.neutral1, 0.65)};
  z-index: 1;
  opacity: 0;
  ${({ animationState }) => cssByAnimationState[animationState]};
`;

const Wrapper = styled.div`
  position: absolute;
  z-index: 10000;
  &::before {
    ${({ backdrop }) => backdrop && backdropCss};
  }
`;

const Overlay = ({ show, children, onShow, onHide, backdrop }) => {
  const wrapperRef = useRef(null);
  return (
    <Transition
      in={show}
      mountOnEnter
      unmountOnExit
      onEnter={() => {
        wrapperRef.current.scrollTop;
        if (onShow) onShow();
      }}
      onExit={onHide}
      timeout={ANIMATION_DURATION}
    >
      {animationState =>
        ReactDOM.createPortal(
          <Wrapper ref={wrapperRef} animationState={animationState} backdrop={backdrop}>
            {children}
          </Wrapper>,
          document.body,
        )
      }
    </Transition>
  );
};

Overlay.propTypes = {
  show: PropTypes.bool.isRequired,
  onShow: PropTypes.func,
  onHide: PropTypes.func,
  children: PropTypes.node,
  backdrop: PropTypes.bool,
};

Overlay.defaultProps = {
  backdrop: true,
};

export default Overlay;
