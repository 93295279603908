import { Query } from 'react-apollo';
import legacyNewsQuery from 'graphql/queries/legacyNews.graphql';
import LoadingPage from 'components/pages/LoadingPage';
import ErrorLoadingPage from 'components/pages/ErrorLoadingPage';
import NotFoundPage from 'components/pages/NotFoundPage';
import Redirect from 'components/atoms/Redirect';

const RedirectFromLegacyNewsToNew = ({ match }) => (
  <Query
    query={legacyNewsQuery}
    variables={{
      id: match.params.legacyId,
    }}
  >
    {({ loading, data, error }) => {
      if (loading) {
        return <LoadingPage />;
      }

      if (error) {
        return <ErrorLoadingPage />;
      }

      if (!data.news) {
        return <NotFoundPage />;
      }

      return <Redirect to={`/news/${data.news.id}`} />;
    }}
  </Query>
);

export default RedirectFromLegacyNewsToNew;
