import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css, ThemeContext } from 'styled-components';
import { Transition } from 'react-transition-group';
import Button from 'components/atoms/Button';
import Overlay from 'components/atoms/Overlay';
import LockBodyScroll from 'components/atoms/LockBodyScroll';
import SearchInput from './SearchInput';
import SearchButton from './SearchButton';
import SearchSelect from './SearchSelect';

const ANIMATION_DURATION = 300;

const MobileWrapper = styled.div``;

const StyledSearchButton = styled(SearchButton)`
  svg {
    font-size: 1.7em;
  }
`;

const Heading = styled.span`
  display: block;
  font-weight: 600;
  font-size: 1.2em;
  margin-bottom: 0.5em;
`;

const cssByAnimationState = {
  entering: css`
    transition: transform ${ANIMATION_DURATION}ms;
    transform: none;
  `,
  entered: css`
    transform: none;
  `,
  exiting: css`
    transition: transform ${ANIMATION_DURATION}ms;
    transform: translateY(-100%);
  `,
  exited: css`
    transform: translateY(-100%);
  `,
};

const Bubble = styled.form`
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: ${({ theme }) => theme.colors.secondary};
  padding: 0.5em;
  border-radius: 0 0 0.2em 0.2em;
  z-index: 2;
  transform: translateY(-100%);

  ${({ animationState }) => cssByAnimationState[animationState]};
`;

const StyledSearchSelect = styled(SearchSelect)`
  margin: 0.5em auto 0;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1em;
`;

const StyledButton = styled(Button)`
  margin: 0 0.5em;
`;

const MobileSearch = ({ className, search, onChangeQ, q, type, onChangeType }) => {
  const theme = useContext(ThemeContext);
  const bubbleRef = useRef(null);
  const inputRef = useRef(null);
  const [isBubbleShown, setBubbleShown] = useState(false);

  const showBubble = useCallback(() => {
    setBubbleShown(true);
  }, []);

  const hideBubble = useCallback(() => {
    setBubbleShown(false);
  }, []);

  useEffect(
    () => {
      if (!isBubbleShown) {
        return;
      }

      function handleClick(e) {
        const el = bubbleRef.current;
        const inside = el.contains(e.target);
        if (!inside) {
          hideBubble();
        }
      }

      function handleResize() {
        if (isBubbleShown && window.matchMedia(`(min-width: ${theme.breakpoints[1]}em)`).matches) {
          hideBubble();
        }
      }

      function onEscape({ keyCode }) {
        if (keyCode === 27) {
          hideBubble();
        }
      }

      document.addEventListener('click', handleClick, true);
      document.addEventListener('keydown', onEscape);
      window.addEventListener('resize', handleResize, true);
      inputRef.current.focus();

      return () => {
        document.removeEventListener('click', handleClick, true);
        document.removeEventListener('keydown', onEscape);
        window.removeEventListener('resize', handleResize, true);
      };
    },
    [isBubbleShown, hideBubble, showBubble],
  );

  return (
    <MobileWrapper className={className}>
      <StyledSearchButton onClick={showBubble} aria-label="Открыть панель поиска" />
      <Overlay show={isBubbleShown}>
        <Transition
          in={isBubbleShown}
          appear
          onEnter={() => {
            bubbleRef.current.scrollTop;
          }}
          timeout={ANIMATION_DURATION}
        >
          {bubbleAnimationState => (
            <Bubble ref={bubbleRef} onSubmit={search} animationState={bubbleAnimationState}>
              {isBubbleShown && <LockBodyScroll />}
              <Heading>Поиск товаров</Heading>
              <SearchInput ref={inputRef} onChange={onChangeQ} value={q} />
              <StyledSearchSelect value={type} onChange={onChangeType} />
              <ButtonsWrapper>
                <StyledButton primary type="submit">
                  Найти
                </StyledButton>
                <StyledButton onClick={hideBubble} type="button">
                  Закрыть
                </StyledButton>
              </ButtonsWrapper>
            </Bubble>
          )}
        </Transition>
      </Overlay>
    </MobileWrapper>
  );
};

MobileSearch.propTypes = {
  className: PropTypes.string,
  onChangeQ: PropTypes.func.isRequired,
  onChangeType: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  q: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default MobileSearch;
