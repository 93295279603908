import styled from 'styled-components';
import Container from 'components/atoms/Container';
import CatalogMenu from 'components/molecules/CatalogMenu';
import Search from 'components/molecules/Search';
import BookmarksButton from 'components/molecules/BookmarksButton';
import CartButton from 'components/molecules/CartButton';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 0;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    margin: 0 -0.625em;
  }
`;

const CatalogMenuWrapper = styled.div`
  flex: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    width: 25%;
    padding: 0 0.625em;
  }
`;

const RightCol = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    flex: 1 1 auto;
    padding: 0 0.625em;
  }
`;

const SearchWrapper = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    display: block;
    flex: 1 1 60%;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    padding-left: 0.5em;
    flex: 1 1 40%;
    justify-content: space-between;
  }
`;

const BookmarksButtonWrapper = styled.div`
  margin-right: 0.5em;
`;

const SecondLine = () => (
  <Wrapper>
    <Container>
      <InnerWrapper>
        <CatalogMenuWrapper>
          <CatalogMenu />
        </CatalogMenuWrapper>
        <RightCol>
          <SearchWrapper>
            <Search />
          </SearchWrapper>
          <Buttons>
            <BookmarksButtonWrapper>
              <BookmarksButton />
            </BookmarksButtonWrapper>
            <CartButton />
          </Buttons>
        </RightCol>
      </InnerWrapper>
    </Container>
  </Wrapper>
);

export default SecondLine;
