import PropTypes from 'prop-types';
import { Link as ReactRouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { darken } from 'polished';

const Link = ({ href, children, disabled, transformLocalHrefToRouterLink, ...props }) => {
  const enhancedProps = { ...props };

  if (disabled) {
    return <span className={props.className}>{children}</span>;
  }

  if (typeof href !== 'undefined') {
    const isLocal = /^\/([^/]|$)/.test(href);

    if (!transformLocalHrefToRouterLink || !isLocal) {
      return (
        <a href={href} {...props}>
          {children}
        </a>
      );
    }

    enhancedProps.to = href;
  }

  return <ReactRouterLink {...enhancedProps}>{children}</ReactRouterLink>;
};

Link.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
};

Link.defaultProps = {
  href: undefined,
};

export default styled(Link)`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.neutral2};

  &:hover {
    color: ${({ theme }) => darken(0.05, theme.colors.neutral2)};
  }
`;
