import { Component } from 'react';
import { Mutation } from 'react-apollo';
import styled from 'styled-components';
import { SmartCaptcha } from '@yandex/smart-captcha';
import Button from 'components/atoms/Button';
import Alert from 'components/atoms/Alert';
import constants from 'constants/constants';
import Form from './index';

const StyledAlert = styled(Alert)`
  margin-bottom: 1em;
`;

export default class Factory extends Component {
  state = {
    values: this.props.fields.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.name]: curr.initialValue,
      }),
      {},
    ),
    errors: {},
    error: null,
    success: false,
  };

  handleError = ({ graphQLErrors }) => {
    let error = 'Произошла ошибка. Попробуйте позднее.';
    let errors = {};

    if (
      graphQLErrors &&
      graphQLErrors[0] &&
      graphQLErrors[0].extensions &&
      graphQLErrors[0].extensions.code &&
      graphQLErrors[0].extensions.code === 'BAD_USER_INPUT'
    ) {
      error = graphQLErrors[0].message || null;
      errors = graphQLErrors[0].extensions.errorsForFields || {};
    }

    this.setState({
      error,
      errors,
    });
  };

  handleCompleted = (result) => {
    const fieldNames = this.props.fields
      .filter((field) => !field.readOnly)
      .map((field) => field.name);
    const namesOfFieldsThatShouldBeReseted = this.props.fields
      .filter((field) => !!field.resetAfterSuccess)
      .map((field) => field.name);

    const newValues = Object.keys(result[this.props.action])
      .filter((fieldName) => fieldNames.includes(fieldName))
      .reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: result[this.props.action][curr] || '',
        }),
        {},
      );

    this.setState((state) => ({
      values: {
        ...state.values,
        ...newValues,
        ...namesOfFieldsThatShouldBeReseted.reduce(
          (prev, curr) => ({
            ...prev,
            [curr]: '',
          }),
          {},
        ),
      },
      success: true,
    }));
  };

  clear = () => {
    this.setState({
      errors: {},
      error: null,
      success: false,
    });
  };

  handleFocus = ({ target: { name } }) => {
    this.setState((state) => ({
      errors: {
        ...state.errors,
        [name]: null,
      },
    }));
  };

  setValue = (name, value) => {
    this.setState((state) => ({
      values: {
        ...state.values,
        [name]: value,
      },
    }));
  };

  handleChange = ({ target }) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setValue(name, value);
  };

  render() {
    const data = this.props.fields
      .filter((field) => !field.readOnly)
      .reduce(
        (prev, curr) => ({
          ...prev,
          [curr.name]: this.state.values[curr.name],
        }),
        {},
      );

    if (this.props.customData) {
      Object.assign(data, this.props.customData);
    }

    if (this.state.success && this.props.renderOnSuccess) {
      return this.props.renderOnSuccess;
    }

    return (
      <Mutation
        mutation={this.props.mutation}
        variables={this.props.doNotWrapVariablesToDataObject ? data : { data }}
        update={this.props.update}
        onError={this.handleError}
        onCompleted={this.handleCompleted}
      >
        {(action, { loading }) => (
          <>
            {this.state.error && <StyledAlert type="error">{this.state.error}</StyledAlert>}
            {this.state.success && (
              <StyledAlert type="success">
                {this.props.successMessage || 'Данные успешно сохранены'}
              </StyledAlert>
            )}
            <Form
              method="post"
              onSubmit={(event) => {
                event.preventDefault();
                this.clear();
                action();
              }}
              noBorders={this.props.noBorders}
            >
              {this.props.fields.map((field) => {
                if (field.type === 'checkbox') {
                  return (
                    <Form.Control key={field.name}>
                      <Form.CheckboxLabel>
                        <Form.Checkbox
                          id={field.name}
                          name={field.name}
                          checked={this.state.values[field.name]}
                          onChange={this.handleChange}
                          disabled={loading}
                          required={field.required}
                        />
                        {field.label}
                      </Form.CheckboxLabel>
                      <Form.InputErrors errors={this.state.errors[field.name]} />
                    </Form.Control>
                  );
                }

                if (field.type === 'textarea') {
                  return (
                    <Form.Control key={field.name}>
                      <Form.Label htmlFor={field.name}>
                        {field.label}
                        {field.showRequired && (
                          <>
                            {' '}
                            <Form.Required />
                          </>
                        )}
                      </Form.Label>
                      <Form.Textarea
                        id={field.name}
                        name={field.name}
                        value={this.state.values[field.name]}
                        rows={field.rows}
                        onChange={field.readOnly ? () => {} : this.handleChange}
                        disabled={loading}
                        error={!!this.state.errors[field.name]}
                        onFocus={this.handleFocus}
                        required={field.required}
                        readOnly={field.readOnly}
                      />
                      <Form.InputErrors errors={this.state.errors[field.name]} />
                    </Form.Control>
                  );
                }

                if (field.type === 'captcha') {
                  return (
                    <Form.Control key={field.name}>
                      <Form.Label>
                        {field.label}
                        {field.showRequired && (
                          <>
                            {' '}
                            <Form.Required />
                          </>
                        )}
                      </Form.Label>
                      {loading ? null : (
                        <div>
                          <SmartCaptcha
                            sitekey={constants.CAPTCHA_SITEKEY}
                            onSuccess={(value) => this.setValue(field.name, value)}
                          />
                        </div>
                      )}
                      <Form.InputErrors errors={this.state.errors[field.name]} />
                    </Form.Control>
                  );
                }

                return (
                  <Form.Control key={field.name}>
                    <Form.Label htmlFor={field.name}>
                      {field.label}
                      {field.showRequired && (
                        <>
                          {' '}
                          <Form.Required />
                        </>
                      )}
                    </Form.Label>
                    <Form.Input
                      type={field.type}
                      id={field.name}
                      name={field.name}
                      value={this.state.values[field.name]}
                      onChange={field.readOnly ? () => {} : this.handleChange}
                      disabled={loading}
                      error={!!this.state.errors[field.name]}
                      onFocus={this.handleFocus}
                      required={field.required}
                      readOnly={field.readOnly}
                    />
                    <Form.InputErrors errors={this.state.errors[field.name]} />
                  </Form.Control>
                );
              })}
              <Button type="submit" primary disabled={loading}>
                {loading ? 'Загрузка...' : this.props.submitText || 'Сохранить'}
              </Button>
            </Form>
          </>
        )}
      </Mutation>
    );
  }
}
