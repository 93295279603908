import styled, { css } from 'styled-components';
import OriginalInput from 'components/atoms/Input';
import OriginalSelect from 'components/atoms/Select';
import OriginalTextarea from 'components/atoms/Textarea';
import Factory from './Factory';

const bordersCss = css`
  border-radius: 0.25em;
  border: 0.125em solid ${({ theme }) => theme.colors.neutral4};
  background-color: ${({ theme }) => theme.colors.neutral5};
  padding: 1em;
`;

const Form = styled.form`
  display: block;
  ${({ noBorders }) => noBorders || bordersCss};
`;

const Control = styled.div`
  margin-bottom: 1.5em;
`;

const Label = styled.label`
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0.3em;
`;

const Input = styled(OriginalInput)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary};
`;

const Select = styled(OriginalSelect)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary};
`;

const Textarea = styled(OriginalTextarea)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary};
  resize: vertical;
  min-height: 5em;
`;

const CheckboxLabel = styled(Label)`
  font-weight: normal;
`;

const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  margin: 0 0.714em 0 0;
`;

const Required = styled.span.attrs({
  children: '*',
})`
  color: #ff0000;
`;

const InputError = styled.div`
  color: ${({ theme }) => theme.colors.errorText};
  margin-top: 0.5em;
`;

const InputErrors = ({ errors }) =>
  errors && errors.length > 0 ? <InputError>{errors.join(' ')}</InputError> : null;

export default Form;

const exports = {
  Control,
  Label,
  Input,
  Select,
  Textarea,
  InputError,
  InputErrors,
  CheckboxLabel,
  Checkbox,
  Required,
  Factory,
};

Object.assign(Form, exports);

export {
  Control,
  Label,
  Input,
  Select,
  Textarea,
  InputError,
  InputErrors,
  CheckboxLabel,
  Checkbox,
  Required,
  Factory,
};
