import styled from 'styled-components';
import Button from 'components/atoms/Button';
import Link from 'components/atoms/Link';

const Proxy = ({ primary, secondary, neutral, theme, inline, big, ...rest }) => <Link {...rest} />;

export default styled(Button).attrs(() => ({
  as: Proxy,
}))`
  text-decoration: none;
`;
