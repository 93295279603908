import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import MainTemplate from 'components/templates/MainTemplate';
import Link from 'components/atoms/Link';
import Status from 'components/atoms/Status';

const NotFoundPage = () => {
  useEffect(() => {
    if (typeof window.ym !== 'undefined') {
      window.ym(28073193, 'reachGoal', '404error');
    }
  }, []);

  return (
    <Status code={404}>
      <MainTemplate>
        <Helmet>
          <title>404 — страница не найдена</title>
        </Helmet>
        <h1>404 &mdash; страница не найдена</h1>
        <p>
          Возможно, запрашиваемая Вами страница была перенесена или удалена. Также возможно, Вы
          допустили небольшую опечатку при вводе адреса.
        </p>
        <p>
          Вы можете:
          <br />
          &mdash; Перейти на <Link to="/">главную страницу</Link>
        </p>
      </MainTemplate>
    </Status>
  );
};

export default NotFoundPage;
