import { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;

const Dots = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;

const DotsWrapper = styled.span`
  position: relative;
`;

class Loading extends Component {
  state = {
    dots: '',
  };

  componentDidMount() {
    this.interval = setInterval(this.iterate, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  iterate = () => {
    this.setState((state) => ({
      dots: state.dots.length === 3 ? '' : `${state.dots}.`,
    }));
  };

  render() {
    const { className } = this.props;

    return (
      <Wrapper className={className}>
        Загрузка
        <DotsWrapper>
          <Dots>{this.state.dots}</Dots>
        </DotsWrapper>
      </Wrapper>
    );
  }
}

export default Loading;
