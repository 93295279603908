import PropTypes from 'prop-types';
import styled from 'styled-components';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';

const Wrapper = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.secondary};
  border: 0.0625em solid ${({ theme }) => theme.colors.neutral4};
  border-radius: 0.25em;
  align-items: center;
  padding-right: 0.5em;
`;

const StyledInput = styled(({ component: Component, ...rest }) => <Component {...rest} />)`
  && {
    border: none;
    border-radius: 0;
    padding: 0.3em 0.5em;
    width: 100%;
  }
`;

StyledInput.defaultProps = {
  component: Input,
};

const Divider = styled.div`
  width: 1px;
  height: 1em;
  background-color: ${({ theme }) => theme.colors.neutral4};
  margin: 0 0.5em;
  flex: none;
`;

const StyledButton = styled(({ component: Component, ...rest }) => <Component {...rest} />)`
  && {
    padding: 0.3em;
    flex: none;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.neutral2};
  }
`;

StyledButton.defaultProps = {
  component: Button,
};

const InputGroup = ({ children }) => <Wrapper>{children}</Wrapper>;

InputGroup.Input = StyledInput;
InputGroup.Divider = Divider;
InputGroup.Button = StyledButton;

InputGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InputGroup;
