import React, { useContext, Component } from 'react';
import PropTypes from 'prop-types';

const CatalogMenuContext = React.createContext({});

class CatalogMenuContextProvider extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    value: PropTypes.shape({
      isMenuShownOnDesktop: PropTypes.bool,
      ref: PropTypes.object,
    }),
  };

  toggleMenuOnDesktop = (callback) => {
    this.setState(
      (state) => ({
        isMenuShownOnDesktop: !state.isMenuShownOnDesktop,
      }),
      callback,
    );
  };

  state = {
    isMenuShownOnDesktop: !!this.props.value.isMenuShownOnDesktop,
    toggleMenuOnDesktop: this.toggleMenuOnDesktop,
    ref: this.props.value.ref,
  };

  render() {
    return (
      <CatalogMenuContext.Provider value={this.state}>
        {this.props.children}
      </CatalogMenuContext.Provider>
    );
  }
}

const useCatalogMenuContext = () => {
  const context = useContext(CatalogMenuContext);

  return context;
};

export default {
  Provider: CatalogMenuContextProvider,
  Consumer: CatalogMenuContext.Consumer,
  useCatalogMenuContext,
};
