import styled from 'styled-components';
import Select from 'components/atoms/Select';

const SearchSelect = styled(Select).attrs({
  children: (
    <>
      <option value="name">По названию</option>
      <option value="article">По артикулу</option>
    </>
  ),
})``;

export default SearchSelect;
