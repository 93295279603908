import styled from 'styled-components';

const Select = styled.select`
  display: block;
  border: 1px solid ${({ theme }) => theme.colors.neutral4};
  border-radius: 0.25em;
  padding: 0.5em;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  min-width: 0;
  outline: 0;
  background: none;

  &:active,
  &:focus {
    background-color: ${({ theme }) => theme.colors.neutral4};
  }
`;

export default Select;
