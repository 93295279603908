import React from 'react';
import styled from 'styled-components';
import FocusLock from 'react-focus-lock';
import EventListener from 'react-event-listener';
import CloseIcon from 'react-icons/lib/md/close';
import Overlay from 'components/atoms/Overlay';
import LockBodyScroll from 'components/atoms/LockBodyScroll';
import ClickAwayListener from 'components/atoms/ClickAwayListener';
import Button from 'components/atoms/Button';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1;
  overflow-y: auto;
`;

const WindowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  min-height: 100%;
`;

const Window = styled.div`
  border-radius: 0.25em;
  background-color: #ffffff;
  width: 40em;
  max-width: 100%;
  padding: 1em;
  transition: opacity 0.3s linear;
  opacity: ${({ show }) => (show ? 1 : 0)};
  position: relative;
`;

const CloseButton = styled(Button)`
  border-radius: 100%;
  width: 2em;
  height: 2em;
  position: absolute;
  top: -0.7em;
  right: -0.7em;
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Title = styled.div`
  font-size: 1.5em;
  margin: 0 0 0.5em;
`;

const Actions = styled.div`
  margin: -0.25em -0.5em;
`;

const Action = styled.div`
  margin: 0.25em 0.5em;
`;

const Modal = ({ title, actions, children, show, onHide, ...rest }) => (
  <>
    {show && <LockBodyScroll />}
    {show && (
      <EventListener
        target="document"
        onKeyDown={(event) => {
          if (onHide && event.keyCode === 27) {
            onHide(event);
          }
        }}
      />
    )}
    <Overlay show={show} {...rest}>
      <FocusLock>
        <Wrapper>
          <WindowWrapper>
            <ClickAwayListener onClickAway={onHide}>
              <Window show={show}>
                <CloseButton neutral onClick={onHide}>
                  <StyledCloseIcon />
                </CloseButton>
                {title && <Title>{title}</Title>}
                {children}
                {actions && actions.length > 0 && (
                  <Actions>
                    {actions.map((element, i) => (
                      <Action key={i} as={(props) => React.cloneElement(element, props)} />
                    ))}
                  </Actions>
                )}
              </Window>
            </ClickAwayListener>
          </WindowWrapper>
        </Wrapper>
      </FocusLock>
    </Overlay>
  </>
);

export default Modal;
