import { Query } from 'react-apollo';
import legacyItemQuery from 'graphql/queries/legacyItem.graphql';
import LoadingPage from 'components/pages/LoadingPage';
import ErrorLoadingPage from 'components/pages/ErrorLoadingPage';
import NotFoundPage from 'components/pages/NotFoundPage';
import Redirect from 'components/atoms/Redirect';

const RedirectFromLegacyItemToNew = ({ match }) => (
  <Query
    query={legacyItemQuery}
    variables={{
      id: match.params.legacyId,
    }}
  >
    {({ loading, data, error }) => {
      if (loading) {
        return <LoadingPage />;
      }

      if (error) {
        return <ErrorLoadingPage />;
      }

      if (!data.item) {
        return <NotFoundPage />;
      }

      return <Redirect to={`/catalog/${data.item.category.translit}/${data.item.id}`} />;
    }}
  </Query>
);

export default RedirectFromLegacyItemToNew;
