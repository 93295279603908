import Modal from 'components/atoms/Modal';
import sendMessageToOfficeMutation from 'graphql/mutations/sendMessageToOffice.graphql';
import Form from 'components/atoms/Form';
import Link from 'components/atoms/Link';
import Alert from 'components/atoms/Alert';

const SendMessageToOffice = ({
  isModalShown,
  onModalHide,
  title,
  initialMessage,
  messageRequired,
  subject,
  successMessage,
  emailRequired,
}) => (
  <Modal show={isModalShown} title={title} onHide={onModalHide}>
    <Form.Factory
      mutation={sendMessageToOfficeMutation}
      action="sendMessageToOffice"
      doNotWrapVariablesToDataObject
      fields={[
        {
          name: 'name',
          label: 'Ваше имя',
          initialValue: '',
          type: 'text',
          showRequired: true,
          required: true,
        },
        {
          name: 'phone',
          label: 'Номер телефона',
          initialValue: '',
          type: 'text',
          showRequired: true,
          required: true,
        },
        {
          name: 'email',
          label: 'Email',
          initialValue: '',
          type: 'email',
          showRequired: emailRequired || false,
          required: emailRequired || false,
        },
        {
          name: 'message',
          label: 'Комментарий',
          initialValue: initialMessage || '',
          type: 'textarea',
          rows: 5,
          showRequired: messageRequired || false,
          required: messageRequired || false,
        },
        {
          name: 'agreement',
          label: (
            <>
              С{' '}
              <Link href="/privacy-policy" target="_blank">
                политикой конфиденциальности
              </Link>{' '}
              согласен
            </>
          ),
          initialValue: false,
          type: 'checkbox',
          required: true,
        },
        {
          type: 'captcha',
          name: 'captcha',
          required: true,
          showRequired: true,
          label: 'Проверка',
        }
      ]}
      customData={{
        subject: subject || 'Сообщение с сайта',
      }}
      submitText="Отправить сообщение"
      renderOnSuccess={
        <Alert type="success">
          {successMessage || 'Ваш запрос успешно отправлен. Ожидайте звонка (письма).'}
        </Alert>
      }
      noBorders
    />
  </Modal>
);

export default SendMessageToOffice;
