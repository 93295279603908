import React, { useContext, useEffect, useRef } from 'react';

const isBrowser = typeof document !== 'undefined';

const Context = React.createContext(null);

export const HydrationContextProvider = ({ children }) => {
  const isHydratedRef = useRef(!isBrowser);

  useEffect(() => {
    setTimeout(() => {
      isHydratedRef.current = true;
    }, 0);
  }, []);

  return <Context.Provider value={isHydratedRef.current}>{children}</Context.Provider>;
};

export function useHydrated() {
  const isHydrated = useContext(Context);

  if (isHydrated === null) {
    throw new Error('you trying to use useHydrated outside of HydrationContextProvider');
  }

  return isHydrated;
}
