import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'raf/polyfill';
import 'formdata-polyfill';
import 'url-search-params-polyfill';
import 'unfetch/polyfill';

import './sentry';
import './lib/analytics/init';

import ReactDOM from 'react-dom';
import { Workbox } from 'workbox-window';
import { loadableReady } from '@loadable/component';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import getAuthFlowLink from 'graphql/links/authFlow';
import * as analytics from 'lib/analytics';

import App from './app';

const cache = new InMemoryCache();

const client = new ApolloClient({
  link: ApolloLink.from([
    getAuthFlowLink(),
    createPersistedQueryLink(),
    createHttpLink({
      uri: '/graphql',
      credentials: 'same-origin',
    }),
  ]),
  // eslint-disable-next-line no-underscore-dangle
  cache: cache.restore(window.__APOLLO_STATE__),
  resolvers: {},
});

cache.writeData({ data: { isAuthenticated: null } });

const root = document.getElementById('root');

const render = (Component) => {
  ReactDOM.hydrate(
    <HelmetProvider>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Component />
        </BrowserRouter>
      </ApolloProvider>
    </HelmetProvider>,
    root,
  );
};

loadableReady(() => {
  render(App);
});

window.addEventListener(
  'load',
  () => {
    if ('requestIdleCallback' in window) {
      window.requestIdleCallback(() => {
        analytics.load();
      });
    } else {
      analytics.load();
    }
  },
  false,
);

if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
  const wb = new Workbox('/service-worker.js');
  wb.register().catch((error) => {
    console.error(error);
  });
}
