import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import MainTemplate from 'components/templates/MainTemplate';
import Loading from 'components/atoms/Loading';

const StyledMainTemplate = styled(({ className, ...rest }) => (
  <MainTemplate containerClassName={className} {...rest} />
))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledLoading = styled(Loading)`
  font-size: 2em;
  font-weight: 600;
`;

const LoadingPage = () => (
  <StyledMainTemplate>
    <Helmet titleTemplate="%s">
      <title>...</title>
    </Helmet>
    <StyledLoading />
  </StyledMainTemplate>
);

export default LoadingPage;
