import constants from 'constants/constants';

export const load = () => {
  const cookies = Object.fromEntries(document.cookie.split('; ').map((x) => x.split('=')));

  if (process.env.NODE_ENV !== 'production' || !!cookies.disable_ya_ga) {
    return;
  }

  const gaScript = document.createElement('script');
  gaScript.src = 'https://www.google-analytics.com/analytics.js';
  gaScript.async = true;
  document.body.appendChild(gaScript);

  const ymScript = document.createElement('script');
  ymScript.src = 'https://mc.yandex.ru/metrika/tag.js';
  ymScript.async = true;
  document.body.appendChild(ymScript);
};

export const pageview = () => {
  if (typeof window.ym !== 'undefined') {
    window.ym(constants.YM_ID, 'hit', document.location.href);
  }
  if (typeof window.ga !== 'undefined') {
    window.ga(
      'set',
      'page',
      window.location.href.replace(
        new RegExp(`^${window.location.protocol}//${window.location.host}`),
        '',
      ),
    );
    window.ga('send', 'pageview');
  }
};

export const order = (orderData) => {
  if (typeof window.ym !== 'undefined') {
    window.ym(constants.YM_ID, 'reachGoal', 'order');
  }
  if (typeof window.ga !== 'undefined') {
    window.ga('ecommerce:addTransaction', {
      id: orderData.number,
      revenue: orderData.summary.total,
    });
    orderData.items.forEach((orderItem) => {
      window.ga('ecommerce:addItem', {
        id: orderData.number,
        name: orderItem.name,
        sku: orderItem.item && orderItem.item.article,
        category: orderItem.item && orderItem.item.category && orderItem.item.category.name,
        price: orderItem.price,
        quantity: orderItem.quantity,
      });
    });
    window.ga('ecommerce:send');
  }
};
