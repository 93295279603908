import Icon from 'react-icon-base';

const HeartIcon = (props) => (
  <Icon viewBox="0 0 16 15" {...props}>
    <g>
      <path d="M11.6434 0.000305176C13.9205 0.000305176 15.74 1.88086 15.9735 4.47285C15.9917 4.58734 16.0674 5.18988 15.8373 6.1722C15.5055 7.58983 14.7394 8.87868 13.6221 9.89978L7.95215 15L2.37793 9.89948C1.26062 8.87868 0.494385 7.58951 0.16272 6.1722C-0.0675049 5.18958 0.00817871 4.58704 0.0267334 4.47253C0.259888 1.88054 2.07971 0 4.35657 0C5.84924 0 7.18127 0.806396 7.95581 2.14273C8.73743 0.823456 10.1265 0.000305176 11.6434 0.000305176ZM2.46143 4.98853C2.46143 3.61992 3.5658 2.50636 4.9231 2.50636C5.0929 2.50636 5.23083 2.36766 5.23083 2.19609C5.23083 2.02451 5.09326 1.88582 4.9231 1.88582C3.22632 1.88582 1.84595 3.27769 1.84595 4.98853C1.84595 5.1601 1.98352 5.29878 2.15369 5.29878C2.32385 5.29878 2.46143 5.1601 2.46143 4.98853Z" />
    </g>
  </Icon>
);

export default HeartIcon;
