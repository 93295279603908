import { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { withRouter } from 'react-router-dom';
import ErrorLoadingPage from 'components/pages/ErrorLoadingPage';
import LoadingPage from 'components/pages/LoadingPage';

@withRouter
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, loading: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, loading: true };
  }

  componentDidCatch(error, info) {
    if (error.request && !('serviceWorker' in navigator && navigator.serviceWorker.controller)) {
      window.location.reload();
      return;
    }

    this.setState({
      loading: false,
    });

    if (process.env.NODE_ENV !== 'production') {
      console.error(error);
    } else {
      Sentry.withScope((scope) => {
        scope.setExtras(info);
        Sentry.captureException(error);
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.hasError && this.props.location !== prevProps.location) {
      window.location.reload();
    }
  }

  render() {
    if (this.state.loading) {
      return <LoadingPage />;
    }

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorLoadingPage />;
    }

    return this.props.children;
  }
}
