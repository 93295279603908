import { ApolloLink } from 'apollo-link';
import isAuthenticatedQuery from 'graphql/queries/isAuthenticated.graphql';

const afterwareLink = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    const context = operation.getContext();
    const { errors } = response;
    const {
      cache,
      response: { headers },
    } = context;

    const wasAuthenticated = cache.readQuery({ query: isAuthenticatedQuery }).isAuthenticated;

    if (headers) {
      const userId = headers.get('user-id');

      cache.writeData({ data: { isAuthenticated: !!userId } });
    }

    if (errors) {
      errors.forEach((err) => {
        if (err.extensions && err.extensions.code === 'UNAUTHENTICATED') {
          cache.writeData({ data: { isAuthenticated: false } });
        }
      });
    }

    const { isAuthenticated } = cache.readQuery({ query: isAuthenticatedQuery });

    if (wasAuthenticated !== null && wasAuthenticated && !isAuthenticated) {
      // елси вышли, то надо сбросить инфу о текущем пользователе
      cache.writeData({ data: { currentUser: null } });
    }

    return response;
  }),
);

export default () => afterwareLink;
