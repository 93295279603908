import styled from 'styled-components';
import { Query } from 'react-apollo';
import CartIcon from 'react-icons/lib/md/shopping-cart';
import cartQuery from 'graphql/queries/cart.graphql';
import ButtonLink from 'components/atoms/ButtonLink';

const StyledButton = styled(ButtonLink)`
  padding: 0.3em 0.5em;
  display: flex;
  align-items: center;
`;

const StyledCartIcon = styled(CartIcon)`
  display: block;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.2em;
`;

const Counter = styled.div`
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 0.3em;
  font-size: 0.9em;
  font-weight: 600;
  padding: 0.2em 0.5em;
  margin-left: 0.3em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    margin-left: 0;
  }
`;

const Text = styled.span`
  display: none;
  margin: 0 0.3em;
  white-space: nowrap;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    display: block;
  }
`;

const CartButton = () => (
  <Query query={cartQuery}>
    {({ data, loading, error }) => (
      <StyledButton secondary to="/cart">
        <StyledCartIcon />
        {!loading && !error && (
          <>
            <Text>{`${data.cart.total.toFixed(2)} ₽`}</Text>
            <Counter>{data.cart.number}</Counter>
          </>
        )}
      </StyledButton>
    )}
  </Query>
);

export default CartButton;
