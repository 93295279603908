import { useEffect, useState } from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, useLocation } from 'react-router-dom';
import loadable from '@loadable/component';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import * as analytics from 'lib/analytics';
import theme from 'constants/theme';
import { title } from 'constants/meta';
import ErrorBoundary from 'components/organisms/ErrorBoundary';
import LoadingPage from 'components/pages/LoadingPage';
import ProtectedRoute from 'components/atoms/ProtectedRoute';
import Redirect from 'components/atoms/Redirect';
import RedirectFromLegacyItemToNew from 'components/molecules/RedirectFromLegacyItemToNew';
import RedirectFromLegacySaleToNew from 'components/molecules/RedirectFromLegacySaleToNew';
import RedirectFromLegacyNewsToNew from 'components/molecules/RedirectFromLegacyNewsToNew';
import { HydrationContextProvider } from 'components/HydrationContext';
import currentUserForProfileSettingsQuery from 'graphql/queries/currentUserForProfileSettings.graphql';
import currentUserForProfileBookmarksQuery from 'graphql/queries/currentUserForProfileBookmarks.graphql';
import currentUserForProfileOrdersQuery from 'graphql/queries/currentUserForProfileOrders.graphql';

dayjs.locale('ru');

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    color: ${theme.colors.text};
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 1rem;
    fill: currentColor;
    -webkit-font-smoothing: antialiased;
  }

  * {
    box-sizing: border-box;
  }
`;

const fallback = <LoadingPage />;

const HomePage = loadable(() => import(/* webpackPrefetch: true */ 'components/pages/HomePage'), {
  fallback,
});
const CatalogPage = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/CatalogPage'),
  {
    fallback,
  },
);
const CatalogCategoryPage = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/CatalogCategoryPage'),
  {
    fallback,
  },
);
const CatalogItemPage = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/CatalogItemPage'),
  {
    fallback,
  },
);
const SignUpPage = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/SignUpPage'),
  {
    fallback,
  },
);
const VerifyEmailPage = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/VerifyEmailPage'),
  {
    fallback,
  },
);
const SignInPage = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/SignInPage'),
  {
    fallback,
  },
);
const ProfileSettingsPage = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/ProfileSettingsPage'),
  {
    fallback,
  },
);
const ProfileBookmarksPage = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/ProfileBookmarksPage'),
  {
    fallback,
  },
);
const ProfileOrdersPage = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/ProfileOrdersPage'),
  {
    fallback,
  },
);
const PasswordResetPage = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/PasswordResetPage'),
  {
    fallback,
  },
);
const EmailChangeConfirmationPage = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/EmailChangeConfirmationPage'),
  {
    fallback,
  },
);
const CartPage = loadable(() => import(/* webpackPrefetch: true */ 'components/pages/CartPage'), {
  fallback,
});
const CheckoutPage = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/CheckoutPage'),
  {
    fallback,
  },
);
const SearchPage = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/SearchPage'),
  {
    fallback,
  },
);
const SalesPage = loadable(() => import(/* webpackPrefetch: true */ 'components/pages/SalesPage'), {
  fallback,
});
const SalePage = loadable(() => import(/* webpackPrefetch: true */ 'components/pages/SalePage'), {
  fallback,
});
const NewsesPage = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/NewsesPage'),
  {
    fallback,
  },
);
const NewsPage = loadable(() => import(/* webpackPrefetch: true */ 'components/pages/NewsPage'), {
  fallback,
});
const PrivacyPolicyPage = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/PrivacyPolicyPage'),
  {
    fallback,
  },
);
const AboutPage = loadable(() => import(/* webpackPrefetch: true */ 'components/pages/AboutPage'), {
  fallback,
});
const PaymentAndDeliveryPage = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/PaymentAndDeliveryPage'),
  {
    fallback,
  },
);
const FeedbacksPage = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/FeedbacksPage'),
  {
    fallback,
  },
);
const ContactsPage = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/ContactsPage'),
  {
    fallback,
  },
);
const TeamPage = loadable(() => import(/* webpackPrefetch: true */ 'components/pages/TeamPage'), {
  fallback,
});
const RepresentativesPage = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/RepresentativesPage'),
  {
    fallback,
  },
);
const RepresentativePage = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/RepresentativePage'),
  {
    fallback,
  },
);
const StaticPage = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/StaticPage'),
  {
    fallback,
  },
);
const SubscriptionPage = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/SubscriptionPage'),
  {
    fallback,
  },
);
const SitemapIndex = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/Sitemap'),
  {
    fallback,
  },
);
const Sitemap = loadable(
  () => import(/* webpackPrefetch: true */ 'components/pages/Sitemap/Sitemap'),
  {
    fallback,
  },
);

const AnalyticsHelmet = () => (
  <Helmet>
    <link rel="preload" as="script" href="https://www.google-analytics.com/analytics.js" />
    <link rel="preload" as="script" href="https://mc.yandex.ru/metrika/tag.js" />
  </Helmet>
);

const App = ({ staticContext }) => {
  const [cookies] = useState(() =>
    typeof document !== 'undefined'
      ? Object.fromEntries(document.cookie.split('; ').map((x) => x.split('=')))
      : staticContext.cookies,
  );

  const location = useLocation();

  useEffect(() => {
    analytics.pageview();
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <GlobalStyle />
      <Helmet titleTemplate="%s :: интернет-магазин Золотая Камея" defaultTitle={title.home}>
        <html lang="ru" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="theme-color" content={theme.colors.primary} />
        <link rel="manifest" href="/manifest.json" />
        <link rel="icon" href="/favicon.png" type="image/png" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" type="image/png" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <HydrationContextProvider>
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route
                path="/*/"
                exact
                strict
                component={({ match }) => <Redirect to={`/${match.params[0]}`} />}
              />
              <Route path="/catalog" exact component={CatalogPage} />
              <Redirect from="/catalog/Nasadki-i-pasty" exact to="/catalog/nasadki-polirovalnye" />
              <Redirect from="/catalog/Kruglogubcy-i-klyuviki" exact to="/catalog/Kruglogubcy" />
              <Route path="/catalog/:translit" exact component={CatalogCategoryPage} />
              <Route path="/catalog/:translit/filter-:slug" exact component={CatalogCategoryPage} />
              <Route
                path="/catalog/:translit/:id"
                exact
                component={(props) => <CatalogItemPage {...props} />}
              />
              <ProtectedRoute userRequired={false} path="/signup" exact component={SignUpPage} />
              <ProtectedRoute
                userRequired={false}
                path="/verify-email"
                exact
                component={VerifyEmailPage}
              />
              <ProtectedRoute userRequired={false} path="/signin" exact component={SignInPage} />
              <ProtectedRoute
                userRequired={false}
                path="/password-reset"
                exact
                component={PasswordResetPage}
              />
              <ProtectedRoute
                userRequired={false}
                path="/password-reset/:id"
                exact
                component={PasswordResetPage}
              />
              <Redirect from="/profile" exact to="/profile/orders" />
              <ProtectedRoute
                userRequired
                userQuery={currentUserForProfileSettingsQuery}
                path="/profile/settings"
                exact
                component={ProfileSettingsPage}
              />
              <ProtectedRoute
                userRequired
                userQuery={currentUserForProfileBookmarksQuery}
                path="/profile/bookmarks"
                exact
                component={ProfileBookmarksPage}
              />
              <ProtectedRoute
                userRequired
                userQuery={currentUserForProfileOrdersQuery}
                path="/profile/orders"
                exact
                component={ProfileOrdersPage}
              />
              <Route
                path="/email-change-confirmation"
                exact
                component={EmailChangeConfirmationPage}
              />
              <Route path="/cart" exact component={CartPage} />
              <Route path="/cart/checkout" component={CheckoutPage} />
              <Route path="/search" exact component={SearchPage} />
              <Route path="/sales" exact component={SalesPage} />
              <Redirect from="/sales/page:number(\d+)" exact to={`/sales?page=:number`} />
              <Route path="/sales/id:legacyId(\d+)" exact component={RedirectFromLegacySaleToNew} />
              <Route path="/sales/:id" exact component={SalePage} />
              <Route path="/news" exact component={NewsesPage} />
              <Redirect from="/news/page:number(\d+)" exact to={`/news?page=:number`} />
              <Route path="/news/id:legacyId(\d+)" exact component={RedirectFromLegacyNewsToNew} />
              <Route path="/news/:id" exact component={NewsPage} />
              <Route path="/privacy-policy" exact component={PrivacyPolicyPage} />
              <Route path="/about" exact component={AboutPage} />
              <Route path="/payment-and-delivery" exact component={PaymentAndDeliveryPage} />
              <Route path="/feedbacks" exact component={FeedbacksPage} />
              <Redirect from="/reviews" exact to="/feedbacks" />
              <Route path="/contacts" exact component={ContactsPage} />
              <Route path="/contacts/team" exact component={TeamPage} />
              <Route
                path="/contacts/predstaviteli-v-regionah"
                exact
                component={RepresentativesPage}
              />
              <Route
                path="/contacts/predstaviteli-v-regionah/:key"
                exact
                component={RepresentativePage}
              />
              <Route path="/subscription" exact component={SubscriptionPage} />
              <Redirect from="/info/about-shop" exact to="/about" />
              <Redirect from="/info/payment" exact to="/payment-and-delivery" />
              <Redirect from="/info/delivery" exact to="/payment-and-delivery" />
              <Redirect from="/info/contacts/nashi-sotrudniki" exact to="/contacts/team" />
              <Redirect from="/user/login" exact to="/signin" />
              <Redirect from="/user/registration" exact to="/signup" />
              <Redirect from="/user/reset" exact to="/password-reset" />
              <Redirect from="/user" exact to="/profile" />
              <Redirect from="/user/:section" exact to="/profile/:section" />
              <Redirect
                from="/katalog-opoki-voskovki-Rus-Pravoslavnaya"
                exact
                to="/katalog-opoki-voskovki-Pravoslavnaya"
              />
              <Redirect
                from="/Opros-po-katalogu-Rus-pravoslavnaya"
                exact
                to="/Opros-po-katalogu-pravoslavnaya"
              />
              <Route
                path="/info/*"
                exact
                component={({ match }) => <Redirect to={`/${match.params[0]}`} />}
              />
              <Route path="/item/id:legacyId(\d+)" exact component={RedirectFromLegacyItemToNew} />
              <Route path="/sitemap.xml" exact component={SitemapIndex} />
              <Route path="/sitemap-*.xml" component={Sitemap} />
              <Route component={StaticPage} />
            </Switch>
          </HydrationContextProvider>
        </ErrorBoundary>
      </ThemeProvider>
      {process.env.NODE_ENV === 'production' && !cookies.disable_ya_ga && <AnalyticsHelmet />}
    </>
  );
};

export default App;
