export const title = {
  home: 'Интернет-магазин Золотая Камея - товары для ювелиров',
  catalog: 'Ювелирное оборудование, инструмент, расходные материалы: продажа, доставка по России',
  item: ({ name }) => `${name} :: Купить по выгодной цене`,
  signup: 'Регистрация',
  verifyEmail: 'Подтверждение E-mail',
  signin: 'Вход',
  profile: 'Личный кабинет',
  passwordReset: 'Восстановление пароля',
  cart: 'Корзина',
  checkout: 'Оформление заказа',
  sales: 'Акции и скидки на товары для ювелиров',
  newses: 'Новости',
  privacyPolicy: 'Политика конфиденциальности',
  about: 'Об интернет-магазине компании "Золотая Камея"',
  paymentAndDelivery: 'Оплата и доставка',
  feedbacks: 'Отзывы',
  contacts: 'Контакты компании "Золотая Камея". Адреса, телефоны, время работы',
  team: 'Сотрудники центрального офиса компании "Золотая Камея" в Казани',
  representatives: 'Представители компании "Золотая Камея" в регионах: адреса и телефоны',
};

export const metaKeywords = {
  home: 'всё для ювелиров, все для ювелиров, интернет-магазин, товары для ювелиров, золотая камея',
  catalog: 'ювелирное оборудование, ювелирный инструмент, расходные материалы',
  item: ({ name }) => `${name}, купить, цена, фото, наличие`,
  team: 'сотрудники, золотая камея',
  representatives: 'представители, представительство, золотая камея',
  contacts: 'контакты, Золотая Камея, адреса, телефоны, время работы',
  sales: 'акции, скидки, Золотая Камея',
  newses: 'новости, сайт, Золотая Камея',
};

export const metaDescription = {
  home:
    'Интернет-магазин "Золотая Камея" - всё для ювелиров. С 2005 года продаем товары для ювелиров ведущих мировых производителей с доставкой по всей России.',
  catalog:
    'Продажа ювелирного оборудования, инструмента, расходных материалов в интернет-магазине для ювелиров "Золотая Камея". Доставка по России, купить по низкой цене.',
  item: ({ name, category }) =>
    `Купить товар "${name}" из категории "${category.name}" в интернет-магазине для ювелиров "Золотая Камея". Доставка по России, низкие цены.`,
  team:
    'Сотрудники центрального офиса компании "Золотая Камея" в Казани: фото, занимаемые должности, контактная информация',
  representatives:
    'Информация о представителях компании "Золотая Камея" в регионах России: адреса и телефоны',
  contacts:
    'Контакты компании "Золотая Камея" - адреса центрального офиса и представителей в регионах, телефоны, время работы',
  sales: 'Акции и скидки на товары для ювелиров в интернет-магазине "Золотая Камея".',
  newses:
    'Новости сайта, информация о поступлении товаров, сообщения о конкурсах и розыграшах призов от интернет-магазина для ювелиров "Золотая Камея".',
  news: ({ name }) => `Новости от интернет-магазина для ювелиров "золотая Камея": "${name}".`,
  sale: ({ timeRangeText, name }) =>
    `Акция ${timeRangeText} "${name}" от интернет-магазина для ювелиров "Золотая Камея".`,
  feedbacks: 'Отзывы клиентов и покупателей о компании "Золотая Камея".',
};

export const heading = {
  home: 'Интернет-магазин "Золотая Камея" - всё для ювелиров',
  catalog: 'Каталог ювелирного оборудования и инструмента',
  signup: 'Регистрация',
  verifyEmail: 'Подтверждение E-mail',
  signin: 'Вход',
  profile: 'Личный кабинет',
  passwordReset: 'Восстановление пароля',
  cart: 'Корзина',
  checkout: 'Оформление заказа',
  about: 'Об интернет-магазине компании "Золотая Камея"',
  paymentAndDelivery: 'Оплата и доставка',
  feedbacks: 'Отзывы',
  sales: 'Акции и скидки',
  contacts: 'Контакты компании "Золотая Камея"',
  team: 'Сотрудники центрального офиса компании "Золотая Камея"',
  representatives: 'Представители компании "Золотая Камея" в регионах',
};
