import styled from 'styled-components';
import AccordionIcon from 'react-icons/lib/fa/bars';

const StyledAccordionIcon = styled(AccordionIcon)`
  font-size: 1.5em;
`;

const HamburgerButton = styled.button.attrs({
  children: <StyledAccordionIcon />,
})`
  border: none;
  background: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-align: center;
  user-select: none;
  padding: 0.5em;
  cursor: pointer;
  display: block;
`;

export default HamburgerButton;
