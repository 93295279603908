import constants from 'constants/constants';

if (process.env.NODE_ENV === 'production') {
  window.ym =
    window.ym ||
    ((...args) => {
      (window.ym.a = window.ym.a || []).push(args);
    });
  window.ym.l = 1 * new Date();
  window.ym(constants.YM_ID, 'init', {
    id: constants.YM_ID,
    clickmap: false,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: false,
  });

  window.GoogleAnalyticsObject = 'ga';
  window.ga =
    window.ga ||
    ((...args) => {
      (window.ga.q = window.ga.q || []).push(args);
    });
  window.ga.l = 1 * new Date();

  window.ga('create', constants.GA_TRACKING_ID, 'auto');
  if (typeof navigator !== 'undefined' && navigator.sendBeacon) {
    window.ga('set', 'transport', 'beacon');
  }
  window.ga('send', 'pageview');
  window.ga('require', 'ecommerce');
}
