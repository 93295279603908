import styled from 'styled-components';
import svg from './logo.svg';
import svgCompact from './logo-compact.svg';

const Logo = styled.img.attrs(({ compact }) => ({
  src: compact ? svgCompact : svg,
  alt: 'Золотая Камея',
}))`
  display: block;
`;

export default Logo;
